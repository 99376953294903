// src/components/Loader.js
import React from 'react';
import './loader.css'; // Add this file to style your loader
import { useTypewriter } from "react-simple-typewriter";

export default function Loader() {
  const [text] = useTypewriter({
    words: [
      "Chain Magician Lab",
    ],
    loop: 1,
    deleteSpeed: 1000,
  });

  return (
    <div className="loader  " style={{background:"black"}}>
  <b   className='text-light textt'>
  {text}
  
  </b>
    </div>
  );
}
