import React from 'react'
import './partner.css'
export default function Partners() {
  return (
    <div className='my-5'>
          <section>
        <h1 className='my-5'>Our Partners</h1>
        <div class="slider ">
            <div class="slider-items">
             <a target='_blank' href="https://bookmytime.org.in/">
             <img height={"20px"} src="./img/WhatsApp Image 2024-04-28 at 18.56.09.jpeg "
                    alt=""/>
             </a>
                {/* <img src="https://www.zarla.com/images/apple-logo-2400x2400-20220512-1.png?crop=1:1,smart&width=150&dpr=2"
                    alt=""/>
                <img src="https://www.zarla.com/images/disney-logo-2400x2400-20220513-2.png?crop=1:1,smart&width=150&dpr=2"
                    alt=""/>
                <img src="https://upload.wikimedia.org/wikipedia/en/thumb/4/4d/Loon_%28company%29_logo.svg/800px-Loon_%28company%29_logo.svg.png"
                    alt=""/>
                <img src="https://upload.wikimedia.org/wikipedia/en/thumb/3/37/Jumpman_logo.svg/1200px-Jumpman_logo.svg.png"
                    alt=""/>
                <img src="https://www.svgrepo.com/show/303123/bmw-logo.svg" alt=""/>
                <img src="https://brandlogos.net/wp-content/uploads/2014/12/starbucks_coffee_company-logo_brandlogos.net_9jqys.png"
                    alt=""/>
                <img src="https://www.zarla.com/images/nike-logo-2400x2400-20220504.png?crop=1:1,smart&width=150&dpr=2"
                    alt=""/>
                <img src="https://www.zarla.com/images/apple-logo-2400x2400-20220512-1.png?crop=1:1,smart&width=150&dpr=2"
                    alt=""/>
                <img src="https://www.zarla.com/images/disney-logo-2400x2400-20220513-2.png?crop=1:1,smart&width=150&dpr=2"
                    alt=""/>
                <img src="https://upload.wikimedia.org/wikipedia/en/thumb/4/4d/Loon_%28company%29_logo.svg/800px-Loon_%28company%29_logo.svg.png"
                    alt=""/>
                <img src="https://upload.wikimedia.org/wikipedia/en/thumb/3/37/Jumpman_logo.svg/1200px-Jumpman_logo.svg.png"
                    alt=""/>
                <img src="https://www.svgrepo.com/show/303123/bmw-logo.svg" alt=""/>
                <img src="https://brandlogos.net/wp-content/uploads/2014/12/starbucks_coffee_company-logo_brandlogos.net_9jqys.png"
                    alt=""/> */}


            </div>
        </div>

    </section>
    </div>
  )
}
