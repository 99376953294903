import React, { useState, useEffect } from 'react';
import Header from './header/header';
import HomePage from './homePage/homePage';
import WorkOn from './workOn/workOn';
import Partners from './partners/partners';
import WhyUs from './whyus/whyUs';
import Loader from './load/loader';
import { Helmet } from 'react-helmet';




export default function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
  
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader/>;
  }

  return (
    <>
  
      <Header />  
      <HomePage />
      <WorkOn />
      <Partners />
      <WhyUs />
      {/* <FatestCross/> */}
    </>
  );
}
