import React from "react";
import "./whyUs.css";

export default function WhyUs() {
  return (
    <div>
      <div className="setbg_vd py-5">
        {/* <h1 className="text-center" style={{ color: "#b6eb00" }}>
          WHY DECRYPTCODERS
        </h1> */}
        <div class="section-header text-center">
        <h2 class="fw-bold fs-1">
        WHY
          <span class="b-class-secondary" style={{ color: "#b6eb00" }}> Chain Magician  </span>
        </h2>
        <p class="sec-icon"><i class="fa-solid fa-gear"></i></p>
      </div>
        <div className="container">
          <div className="row my-4 center-items">
            <div className="col-sm p-5">
              <p className="fs-5 pt-5 fw-bold">Experience and Expertise</p>
              <ul>
                <li>
                  Years of experience in delivering custom software solutions
                  across diverse industries.
                </li>
                <li>
                  Skilled team of developers proficient in the latest
                  technologies and methodologies.
                </li>
              </ul>
            </div>
            <div className="col-sm p-5">
              <video width="100%" autoPlay loop>
                <source
                  src="./video/1109695_Animation_Dark-background_Animation_1280x720.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div className="row mt-4 center-items">
            <div className="col-sm center-items">
              <img height="80%" src="./img/7176408_3572684.svg" alt="" />
            </div>

            <div className="col-sm">
              <p className="fs-5 p-5 fw-bold">Client-Centric Approach</p>
              <ul>
                <li>
                  Prioritizing client satisfaction by understanding their unique
                  requirements and goals
                </li>
                <li>
                  Collaborative development process ensuring transparency,
                  communication, and flexibility
                </li>
              </ul>
            </div>
          </div>

          <div className="row my-4 center-items">
            <div className="col-sm order-sm-1">
              <p className="fs-5 p-5 fw-bold">Timely Delivery</p>
              <ul>
                <li>
                  Commitment to delivering projects on time, within budget, and
                  according to specifications
                </li>
                <li>
                  Efficient project management techniques to streamline
                  development and minimize delays.
                </li>
              </ul>
            </div>
            <div className="col-sm order-sm-2 center-items">
              <img src="./img/5205022_Delivery.svg" height={"80%"} alt="" />
            </div>
          </div>

          <div className="row my-4 center-items">
     
            <div className="col-sm order-sm-2">
              <p className="fs-5 p-5 fw-bold">Innovation and Adaptability</p>
              <ul>
                <li>
                  Embracing innovation and staying updated with emerging
                  technologies to provide cutting-edge solutions.
                </li>
                <li>
                  Agile development methodology enabling rapid prototyping and
                  iterative improvements
                </li>
              </ul>
            </div>
            <div className="col-sm  order-sm-1 p-5 center-items">
              <img height={"400px"} src="./img/inn.webp" alt="" />
            </div> 
          </div>

          <div className="row my-4 center-items">
            <div className="col-sm p-5">
              <p className="fs-5 fw-bold">Support and Maintenance</p>
              <ul>
                <li>
                  Dedicated support team available round-the-clock to address
                  any issues or concerns.
                </li>
                <li>
                  Proactive maintenance services to keep your software running
                  smoothly and securely.
                </li>
              </ul>
            </div>
            <div className="col-sm center-items">
              <img height={"80%"} src="./img/8821548_4018854.svg" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="middle text-center my-5">
        <h1 className="my-5">Contact Us</h1>
        <a
          className="btnnn"
          target="_blank"
          href="https://wa.me/8470847525?text=Hello Chain Magician Lab  This  Message is Regarding Software development "
        >
          <i className="fab fa-whatsapp"></i>
        </a>

        <a className="btnnn" target="_blank" href="https://www.linkedin.com/in/anmolsingh-chainmagicianlab">
  <i className="fab fa-linkedin"></i>
</a>

        {/* <a
          className="btnnn"
          target="_blank"
          href="mailto:chainmagicianlab@gmail.com?subject=Enquiry for custom software development&body=Hello Chain Magician Lab, I want to enquire that"
        > */}
        <a className="btnnn" href="tel:+918470847525">
          <i className="fa fa-phone"></i>
        </a>

        <a className="btnnn"
        target="_blank" href="https://www.instagram.com/Chain_Magician_Lab">
          <i className="fab fa-instagram"></i>
        </a>

        <a className="btnnn"
        target="_blank" href="https://t.me/Chain_Magician_Lab">
          <i className="fab fa-telegram-plane"></i>
        </a>
        <a
          className="btnnn"
          target="_blank"
          href="mailto:chainmagicianlab@gmail.com?subject=Enquiry for custom software development&body=Hello Chain Magician Lab, I want to enquire that"
        >
          <i className="fas fa-envelope"></i>
        </a>
      </div>

      <div className="text-center mb-2 text-dark">
        Copyright© 2024 Chain Magician Lab All Rights Reserved
      </div>
    </div>
  );
}
